<template>
  <ChargingContent>
    <template #title>
      {{ $t('chargingState.showDelayInformation.title') }}
    </template>
    <template #description>
      {{ $t('chargingState.showDelayInformation.description.a') }}<br>{{ $t('chargingState.showDelayInformation.description.b') }}
    </template>
  </ChargingContent>
</template>
