import type { NavigationGuard } from 'vue-router'
import { useChargeStorage } from '~/composables/useChargeStorage'
import { ROUTE_PATH_CONNECT, ROUTE_PATH_INDEX, ROUTE_PATH_SCAN } from '~/config/router/route-paths'
import { setAuthToken } from '~/config/axios'
import { postAuthenticate, postPaymentIntents } from '~/services/direct-payment-authorisation-service'
import { getTariffOfferByEvseIds } from '~/services/price-management-service'
import { getURLSearchParams } from '~/utils/url-search-params'

/**
 * This guard checks if user is already authenticated.
 *
 * 1. if is already authenticated, redirect to the _connect_ view,
 * 2. otherwise move on
 * @see evseIdGuard
 */
export const isAuthenticatedGuard: NavigationGuard = async (to, _from, next) => {
  const { evseId, isAuthenticated } = useChargeStorage()

  if (isAuthenticated(evseId.value))
    next({ path: ROUTE_PATH_CONNECT, query: to.query })
  else
    next()
}

/**
 * This guard will authenticate against the backend if necessary.
 *
 * 1. if `evseId` is not set, redirect to the _scan_ view.
 * 2. otherwise if we have a token related to the `evseId`, set the `Authentication Header` accordingly.
 * 3. otherwise if we have a `paymentIntent` and a `tariffOffer`, load the authentication token from the backend.
 * 4. if there is still no authentication, redirect to the _index_ (aka _loading_) view.
 * 5. otherwise move on
 */
export const authGuard: NavigationGuard = async (to, _from, next) => {
  const { evseId, getSession, isAuthenticated, setSession, paymentIntent } = useChargeStorage()

  if (evseId.value === null) {
    next({ path: ROUTE_PATH_SCAN, replace: true })
    return
  }

  const session = getSession(evseId.value)
  const token = session?.token

  // we already have an authenticated session
  if (typeof token === 'string') {
    setAuthToken(token)
    next()
    return
  }

  const paymentIntentId = session?.paymentIntentId ?? paymentIntent.value?.paymentIntentId

  if (paymentIntentId) {
    await postAuthenticate({
      paymentIntentId,
    }).then((response) => {
      const token = response.data.data.authenticationToken

      setAuthToken(token)
      setSession(evseId.value, {
        ...session,
        token,
      })
    }).catch((error) => {
      console.error(error)
    })
  }

  if (!isAuthenticated(evseId.value)) {
    next({ path: ROUTE_PATH_INDEX, query: to.query })
    return
  }

  next()
}

/**
 * This guard will check if there is an `evseId` available.
 *
 * 1. if there is an `evseId` in the query parameters in the URI, use it.
 * 2. in case user goes back to another `evseId` in browser history, invalidate `tariffOffer` and `paymentIntent`
 * 3. if there is no `evseId`, redirect to the _scan_ view
 * 4. otherwise move on
 */
export const evseIdGuard: NavigationGuard = (_to, _from, next) => {
  const { evseId, tariffOffer, paymentIntent } = useChargeStorage()

  const params = getURLSearchParams()
  const oldEvseId = evseId.value

  evseId.value = params?.get('evseId') || null

  if (typeof oldEvseId === 'string' && typeof evseId.value === 'string' && oldEvseId !== evseId.value) {
    tariffOffer.value = null
    paymentIntent.value = null
  }

  if (evseId.value === null) {
    next({ path: ROUTE_PATH_SCAN })
    return
  }

  next()
}

/**
 * This guard will load a `tariffOffer` from the backend if necessary.
 *
 * 1. if we have an `evseId` and no `tariffOffer`, load the `tariffOffer` related to the `evseId` from the backend.
 * 2. if there is still no `tariffOffer`, redirect to the _index_ (aka _loading_) view.
 * 3. otherwise move on
 */
export const offerGuard: NavigationGuard = async (to, _from, next) => {
  const { evseId, getSession, setSession, tariffOffer } = useChargeStorage()

  let session = getSession(evseId.value)

  if (tariffOffer.value === null && evseId.value !== null) {
    await getTariffOfferByEvseIds({
      evseIds: [evseId.value],
    }).then((response) => {
      tariffOffer.value = response.data.data.at(0) || null
      setSession(evseId.value, {
        ...session,
        authorizationAmount: tariffOffer.value?.authorizationAmount.amount,
        currency: tariffOffer.value?.currency,
        offer: tariffOffer.value?.signedOffer,
      })
      session = getSession(evseId.value)
    }).catch((error) => {
      console.error(error)
    })

    if (typeof session?.offer === 'undefined' || tariffOffer.value === null) {
      if (import.meta.env.DEV)
        console.error('loading tariff offer failed')

      next({ path: ROUTE_PATH_INDEX, query: to.query })
      return
    }
  }

  next()
}

/**
 * This guard will load a `paymentIntent` from the backend if necessary.
 *
 * 1. if we have a `tariffOffer` and no `paymentIntent`, load the `paymentIntent` related to the `tariffOffer`'s `signedOffer` from the backend.
 * 2. if there is still no `paymentIntent`, redirect to the _index_ (aka _loading_) view.
 * 3. otherwise move on
 */
export const paymentIntentGuard: NavigationGuard = async (to, _from, next) => {
  const { evseId, getSession, paymentIntent, setSession, tariffOffer } = useChargeStorage()

  let session = getSession(evseId.value)

  if (paymentIntent.value === null && tariffOffer.value !== null) {
    await postPaymentIntents({ signedOffer: tariffOffer.value.signedOffer }).then((response) => {
      paymentIntent.value = response.data.data || null
      setSession(evseId.value, {
        ...session,
        paymentIntentId: paymentIntent.value?.paymentIntentId,
      })
      session = getSession(evseId.value)
    }).catch((error) => {
      console.error(error)
    })

    if (typeof session?.paymentIntentId === 'undefined' || paymentIntent.value === null) {
      if (import.meta.env.DEV)
        console.error('loading payment intent failed')

      next({ path: ROUTE_PATH_INDEX, query: to.query })
      return
    }
  }

  next()
}

/**
 * This guard will prevent loading a `paymentIntent` from the backend again if payment via
 * Apple Pay, Google Pay, Link, etc. has been authorized as this would invalidate the authentication.
 */
export const stripeRedirectGuard: NavigationGuard = async (to, _from, next) => {
  if (to.query.redirect_status === 'succeeded') {
    for (const key in to.query) {
      if (key !== 'evseId' && key !== 'lang')
        delete to.query[key]
    }

    next({ path: ROUTE_PATH_CONNECT, query: to.query, replace: true })
    return
  }

  next()
}
