import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import { authGuard, evseIdGuard, isAuthenticatedGuard, offerGuard, paymentIntentGuard, stripeRedirectGuard } from './route-guards'
import {
  ROUTE_PATH_CHARGE,
  ROUTE_PATH_CONNECT,
  ROUTE_PATH_ERROR,
  ROUTE_PATH_INDEX,
  ROUTE_PATH_PAYMENT,
  ROUTE_PATH_PAYMENT_CREDIT_CARD,
  ROUTE_PATH_SCAN,
} from './route-paths'
import PageCharge from '~/pages/charge.vue'
import PageConnect from '~/pages/connect.vue'
import PageError from '~/pages/error.vue'
import PageIndex from '~/pages/index.vue'
import PagePayment from '~/pages/payment.vue'
import PagePaymentCreditCard from '~/pages/payment-credit-card.vue'
import PageScan from '~/pages/scan.vue'

const routes: Array<RouteRecordRaw> = [
  {
    component: PageIndex,
    path: ROUTE_PATH_INDEX,
    beforeEnter: multiguard([evseIdGuard, isAuthenticatedGuard]),
  },
  {
    component: PageScan,
    path: ROUTE_PATH_SCAN,
  },
  {
    component: PageCharge,
    path: ROUTE_PATH_CHARGE,
    beforeEnter: multiguard([evseIdGuard, authGuard]),
  },
  {
    component: PageConnect,
    path: ROUTE_PATH_CONNECT,
    beforeEnter: multiguard([evseIdGuard, authGuard]),
  },
  {
    component: PagePayment,
    path: ROUTE_PATH_PAYMENT,
    beforeEnter: multiguard([stripeRedirectGuard, evseIdGuard, isAuthenticatedGuard, offerGuard, paymentIntentGuard]),
  },
  {
    component: PagePaymentCreditCard,
    path: ROUTE_PATH_PAYMENT_CREDIT_CARD,
    beforeEnter: multiguard([evseIdGuard, isAuthenticatedGuard, offerGuard, paymentIntentGuard]),
  },
  {
    component: PageError,
    path: ROUTE_PATH_ERROR,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: ROUTE_PATH_INDEX,
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition)
      return savedPosition
    else
      return { top: 0 }
  },
})

export default router
