import * as Sentry from '@sentry/vue'
import mitt from 'mitt'
import type { App } from 'vue'
import type { Router } from 'vue-router'
import { isLocalEnv, isProdEnv } from './useEnvironment'

export const SENTRY_ERROR_EVENT = 'SENTRY_ERROR_EVENT'

export function useSentry(config: {
  app: App
  dsn: string
  environment: string
  release: string
  router: Router
  tracingTargets: (string | RegExp)[]
}) {
  const { router, tracingTargets, ...rest } = config

  Sentry.init({
    ...rest,
    enabled: !isLocalEnv,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        routeLabel: 'path',
      }),
      Sentry.replayIntegration(),
    ],
    logErrors: true,
    // Capture Replay for 0% of all sessions,
    // plus for 100% of sessions with an error
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.0,
    tracePropagationTargets: tracingTargets,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: isProdEnv ? 0.1 : 1.0,
    trackComponents: true,
    beforeSend(event: Sentry.ErrorEvent, _hint: Sentry.EventHint) {
      // Modify or drop the event here
      if (event.user) {
        // Don't send user's email address
        delete event.user.email
        delete event.user.ip_address
      }

      if (event.message) {
        event.message = event.message
          .replace(/[\w.-]+@[\w.-]+\.[\w.-]+/g, '[email]')
          .replace(/\b(?:\d{1,3}\.){3}\d{1,3}\b/g, '[ip]')
      }

      if (event.transaction) {
        event.transaction
          .replace(/[\w.-]+@[\w.-]+\.[\w.-]+/g, '[email]')
          .replace(/\b(?:\d{1,3}\.){3}\d{1,3}\b/g, '[ip]')
      }

      if (event.tags) {
        Object.keys(event.tags).forEach((value, key) => {
          if (event.tags) {
            event.tags[key] = value
              .replace(/[\w.-]+@[\w.-]+\.[\w.-]+/g, '[email]')
              .replace(/\b(?:\d{1,3}\.){3}\d{1,3}\b/g, '[ip]')
          }
        })
      }

      return event
    },
  })

  mitt().on(SENTRY_ERROR_EVENT, (err: unknown) => {
    Sentry.captureException(err)
  })
}
