import type Stripe from '@stripe/stripe-js'

const appearance: Stripe.Appearance = {
  variables: {
    fontFamily: 'inter, system-ui, sans-serif',
    colorPrimary: '#1E2A2A',
    colorBackground: '#ffffff',
    colorText: '#1E2A2A',
    colorDanger: '#DC262F',
    fontSizeBase: '14px',
    // See all possible variables below
  },
}

export default appearance
