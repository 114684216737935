<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useNotifications } from '@kreait/web-components'
import { useChargeStorage } from '~/composables/useChargeStorage'
import { ROUTE_PATH_CONNECT, ROUTE_PATH_PAYMENT_CREDIT_CARD } from '~/config/router/route-paths'
import { confirmPayment, destroy, stripeExpressCheckoutElement } from '~/config/stripe/expressCheckout'
import { usePaymentNotifications } from '~/composables/usePaymentNotifications'
import { useOperator } from '~/composables/useOperator'

const STRIPE_EXPRESS_CHECKOUT_ELEMENT_ID = 'stripe-express-checkout-element'

const route = useRoute()
const router = useRouter()
const { dismissCurrentNotification } = useNotifications()
const { triggerPaymentFailedNotification, triggerPaymentSuccessNotification } = usePaymentNotifications()
const { evseId, getSession, tariffOffer } = useChargeStorage()

const session = getSession(evseId.value)
const { operator } = useOperator()

const deposit = computed(() =>
  session?.authorizationAmount ?? tariffOffer.value?.authorizationAmount.amount,
)

const currency = computed(() =>
  session?.currency ?? tariffOffer.value?.currency,
)

function handlePaymentResult(result: boolean) {
  dismissCurrentNotification()

  result ? triggerPaymentSuccessNotification() : triggerPaymentFailedNotification()
}

onBeforeMount(() => {
  if (route.query.redirect_status)
    handlePaymentResult(route.query.redirect_status === 'succeeded')
})

onMounted(async () => {
  const expressCheckoutElement = await stripeExpressCheckoutElement()

  expressCheckoutElement?.mount(`#${STRIPE_EXPRESS_CHECKOUT_ELEMENT_ID}`)

  expressCheckoutElement?.on('confirm', async () => {
    const result = await confirmPayment()
    handlePaymentResult(result)

    if (result)
      router.push({ path: ROUTE_PATH_CONNECT, query: route.query, replace: true })
  })

  expressCheckoutElement?.on('ready', ({ availablePaymentMethods }) => {
    if (!availablePaymentMethods) {
      console.error('Stripe express checkout: no payment methods available')
      router.push({ path: ROUTE_PATH_PAYMENT_CREDIT_CARD, query: { ...route.query, hide: 'true' }, replace: true })
    }
  })
})

onBeforeUnmount(async () => {
  destroy()
})
</script>

<template>
  <div class="flex h-full flex-col">
    <h1 class="mx-12 mb-10 rounded-md border-2 border-gray-200 bg-white px-10 pb-5 pt-11 text-center text-lg font-bold shadow-foo">
      <span class="block text-nowrap">{{ $t('welcome') }}</span>
      <span v-if="operator" class="text-nowrap">{{ $t('value.plain', {
        value: `${operator.name} Charging`,
      }) }}</span>
    </h1>
    <div>
      <h2 class="mb-3 text-center font-bold text-gray-700">
        {{ $t('tariff.title') }}
      </h2>
      <PaymentPricingList />
    </div>
  </div>
  <AppFooter>
    <RadixDepositDialog v-if="typeof deposit === 'number'" :deposit="deposit" :currency="currency" />
    <div :id="STRIPE_EXPRESS_CHECKOUT_ELEMENT_ID" class="mb-2" />
    <AppButton appearance="secondary" :to="{ path: ROUTE_PATH_PAYMENT_CREDIT_CARD, query: route.query, replace: true }">
      {{ $t('payment.payWith.creditcard') }}
    </AppButton>
  </AppFooter>
</template>
