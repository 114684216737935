<script setup lang="ts">
import { useChargeStorage } from '~/composables/useChargeStorage'

const { tariffOffer } = useChargeStorage()
</script>

<template>
  <ul v-if="!!tariffOffer" class="bg-white">
    <li v-if="tariffOffer.energyTariff" class="border-y border-gray-100 px-2 py-3.5 text-center">
      <div class="text-xs text-gray-500">
        {{ $t(`tariff.energy.title`) }}
      </div>
      <div>
        <span class="mr-2 font-bold">{{
          (tariffOffer.energyTariff.pricePerKWH / 100).toLocaleString($i18n.locale, {
            style: 'currency',
            currency: tariffOffer.currency,
          })
        }}</span>
        <span class="text-xs text-gray-500">{{ $t('value.perkWh') }}</span>
      </div>
    </li>
    <li v-if="tariffOffer.timeTariff" class="border-b border-gray-100 px-2 py-3.5 text-center">
      <div class="text-xs text-gray-500">
        {{ $t(`tariff.parkingFee.title`) }}
      </div>
      <div>
        <span class="mr-2 font-bold">{{
          (tariffOffer.timeTariff.pricePerMinute / 100).toLocaleString($i18n.locale, {
            style: 'currency',
            currency: tariffOffer.currency,
          })
        }}</span>
        <span class="text-xs text-gray-500">{{ $t('tariff.parkingFee.value', {
          value: tariffOffer.timeTariff.freeMinutesPerSession,
        }) }}</span>
      </div>
    </li>
    <!-- <li class="py-3.5 px-2 border-b border-gray-100 text-center">
      <div class="text-gray-500 text-xs">{{ $t(`tariff.startingFee.title`) }}</div>
      <div>
        <span class="font-bold mr-2">{{
          (tariffOffer.authorizationAmount.amount / 100).toLocaleString($i18n.locale, {
            style: 'currency',
            currency: tariffOffer.currency,
          })
        }}</span>
        <span class="text-gray-500 text-xs">{{ $t('value.perkWh') }}</span>
      </div>
    </li> -->
  </ul>
</template>
