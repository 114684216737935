import type Stripe from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js'

let instance: Stripe.Stripe | null = null

export async function stripe() {
  if (instance === null) {
    instance = await loadStripe(
      import.meta.env.VITE_STRIPE_PUBLIC_KEY,
      {
        stripeAccount: 'acct_1PaFyAHchFgd1boX'
      }
    )

    if (!instance) {
      if (import.meta.env.DEV)
        console.error('failed to create Stripe instance')
    }
  }

  return instance
}
