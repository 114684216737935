import { ref } from 'vue'
import { useChargeSession } from './useChargeSession'
import { useChargeStorage } from './useChargeStorage'
import type { ChargeSession } from '~/services/direct-charge-service.types'
import { ChargingState } from '~/types/ChargingState.enum'

const state = ref<ChargingState>(ChargingState.WAIT_FOR_SESSION_DATA)
let pollTimeout: number | undefined
let shouldPoll = false
let startedTimout: number | undefined

export function useChargeState() {
  const { chargeSession, conflict, fetchCurrent, start, stop, unauthorized } = useChargeSession()
  const { reset } = useChargeStorage()

  const stopPoll = () => {
    shouldPoll = false
    window.clearTimeout(pollTimeout)
    pollTimeout = undefined
  }

  const checkAuthorization = (result: boolean) => {
    if (!result && unauthorized.value) {
      state.value = ChargingState.ERROR_START_PAYMENT_EXPIRED
      reset(true)
      return false
    }

    return true
  }

  const updateChargingStateStartFailed = (session: ChargeSession) => {
    switch (session.reason?.code) {
      case 'CPO_COMMUNICATION_ERROR':
      case 'CPO_COMMUNICATION_TIMEOUT':
        state.value = ChargingState.ERROR_START_COMMUNICATION_TIMEOUT
        break
      case 'CPO_REJECTED_REQUEST':
        state.value = ChargingState.ERROR_START_CPO_REJECTED_REQUEST
        break
      case 'CPO_CABLE_CONNECTION_ERROR':
        state.value = ChargingState.ERROR_START_CPO_CABLE_CONNECTION
        break
      case 'EVSE_RESERVED':
        state.value = ChargingState.ERROR_START_EVSE_RESERVED
        break
      case 'EVSE_IN_USE':
        state.value = ChargingState.ERROR_START_EVESE_IN_USE
        break
      case 'EVSE_REJECTED_REQUEST':
        state.value = ChargingState.ERROR_START_EVSE_REJECTED_REQUEST
        break
      case 'EVSE_OUT_OF_SERVICE':
        state.value = ChargingState.ERROR_START_EVSE_OUT_OF_SERVICE
        break
      case 'USER_REQUESTED_START':
        break
      case 'CPO_ENDED_CHARGING':
        break
      case 'CPO_SENT_CDR':
        break
      case 'CPO_ABORTED_CHARGING':
        break
      case 'USER_REQUESTED_STOP':
        break
      case 'PROLONGED_SESSION_CUT_OFF':
        break
      case 'LIMITED_SESSION_CUT_OFF':
        break
      case 'USER_REQUEST_TIMEOUT':
      case 'INTERNAL_ERROR':
      default:
        state.value = ChargingState.ERROR_START_INTERNAL
        break
    }
  }

  const updateChargingState = async (session: ChargeSession) => {
    switch (session.status) {
      case 'START_REQUESTED':
        state.value = ChargingState.START_REQUESTED
        break
      case 'STARTED':
        if (typeof startedTimout === 'undefined') {
          state.value = ChargingState.STATION_AWAKE
          startedTimout = window.setTimeout(() => {
            state.value = ChargingState.INITIAL_CONNECTION

            startedTimout = window.setTimeout(() => {
              state.value = ChargingState.INITIAL_CONNECTION_DELAYED
            }, 6000)
          }, 4000)
        }
        break
      case 'CHARGING':
        window.clearTimeout(startedTimout)
        startedTimout = undefined
        state.value = ChargingState.CHARGING
        break
      case 'STOP_REQUESTED':
        state.value = ChargingState.STOP_REQUESTED
        break
      case 'STOPPED':
        stopPoll()
        reset(true)
        state.value = ChargingState.STOPPED
        break
      case 'START_FAILED':
        stopPoll()
        updateChargingStateStartFailed(session)
        break
      case 'STOP_FAILED':
        stopPoll()
        state.value = ChargingState.ERROR_STOP_FAILED
        break
    }
  }

  const fetchStatus = async () => {
    const result = await fetchCurrent()

    if (!checkAuthorization(result))
      return false

    if (chargeSession.value)
      await updateChargingState(chargeSession.value)

    return result
  }

  const poll = async () => {
    const result = await fetchStatus()

    if (!checkAuthorization(result)) {
      stopPoll()
      return
    }

    if (shouldPoll) {
      pollTimeout = window.setTimeout(async () => {
        await poll()
      }, 2000)
    }
  }

  const startPoll = async () => {
    if (typeof pollTimeout === 'number') {
      if (import.meta.env.DEV)
        console.warn('poll already started')

      return
    }

    shouldPoll = true
    await poll()
  }

  const onStart = async () => {
    state.value = ChargingState.WAIT_FOR_SESSION_DATA
    const result = await start()

    if (chargeSession.value)
      await updateChargingState(chargeSession.value)

    if (!result) {
      stopPoll()
      // if (conflict.value) {
      //   state.value = ChargingState.UI_ERROR_START_CONFLICT
      // } else
      if (unauthorized.value)
        state.value = ChargingState.ERROR_START_PAYMENT_EXPIRED
      else if (!conflict.value)
        state.value = ChargingState.UI_ERROR_GENERIC
    }

    return result
  }

  const onStop = async () => {
    await stop()

    if (chargeSession.value)
      await updateChargingState(chargeSession.value)
  }

  return { fetchStatus, onStart, onStop, reset, state, startPoll, stopPoll }
}
