import { authorized } from '../config/axios'
import type { ChargeSession, CommandResponse, StartRequest, StopRequest } from './direct-charge-service.types'
import { COMMAND_CANCEL_STOP, COMMAND_START, COMMAND_STOP, SESSION_BY_ID, SESSION_CURRENT } from './direct-charge-service.routes'

const baseURL = import.meta.env.VITE_URL_SERVICE_DIRECT_CHARGE

export function sendStartCommand(params: StartRequest) {
  return authorized.post<CommandResponse>(
    COMMAND_START,
    params,
    {
      baseURL,
    },
  )
}

export function sendStopCommand(params: StopRequest) {
  return authorized.post<CommandResponse>(
    COMMAND_STOP,
    params,
    {
      baseURL,
    },
  )
}

export function sendCancelStopCommand(params: {
  chargeSessionId: string
}) {
  return authorized.post<CommandResponse>(
    COMMAND_CANCEL_STOP,
    params,
    {
      baseURL,
    },
  )
}

export function getCurrentChargeSession() {
  return authorized.get<ChargeSession>(
    SESSION_CURRENT,
    {
      baseURL,
    },
  )
}

export function getChargeSessionById(id: string) {
  return authorized.get<ChargeSession>(
    SESSION_BY_ID.replace(':id', id),
    {
      baseURL,
    },
  )
}
