import { MotionPlugin } from '@vueuse/motion'
import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import router from '~/config/router'
import { i18n } from '~/config/i18n'
import '~/styles/index.css'
import { VERSION } from '~/version'
import { useSentry } from '~/composables/useSentry'
import { environment, isLocalEnv } from '~/composables/useEnvironment'

const app = createApp(App)

useSentry({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment,
  release: VERSION,
  router,
  tracingTargets: [
    'localhost',
    import.meta.env.VITE_URL_SERVICE_DIRECT_PAYMENT_AUTHORISATION,
    import.meta.env.VITE_URL_SERVICE_DIRECT_CHARGE,
    import.meta.env.VITE_URL_SERVICE_PRICE_MANAGEMENT,
  ],
})

app.use(router)
app.use(i18n)
app.use(MotionPlugin)
app.mount('#app')

app.config.errorHandler = (err, vm, info) => {
  if (isLocalEnv)
    console.error(err, vm, info)

  Sentry.withScope((scope) => {
    scope.setExtra('info', info)
    scope.setExtra('vm', vm)
    Sentry.captureException(err)
  })
}

// eslint-disable-next-line no-console
console.log(`version: ${VERSION}`)
