import { unauthorized } from '../config/axios'
import { EVSE_TARIFF_OFFER } from './price-management-service.routes'
import type { TariffOffer, TariffOfferParams } from './price-management-service.types'
import type { Response } from './base.types'

const baseURL = import.meta.env.VITE_URL_SERVICE_PRICE_MANAGEMENT

export function getTariffOfferByEvseIds(params: TariffOfferParams) {
  return unauthorized.get<Response<TariffOffer[]>>(
    EVSE_TARIFF_OFFER,
    {
      baseURL,
      params,
    },
  )
}
