<script setup lang="ts">
import { EvNotifications } from '@kreait/web-components'
import { watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { getURLSearchParams } from './utils/url-search-params'
import { useOperator } from '~/composables/useOperator'

const { operator } = useOperator()
const { locale } = useI18n()
const params = getURLSearchParams()

locale.value = params?.get('lang') ?? 'en'

watchEffect(() => {
  locale.value = params?.get('lang') ?? 'en'
})
</script>

<template>
  <div class="relative mx-auto flex h-full max-w-xl flex-col border-x border-gray-200 bg-white">
    <header class="relative h-32">
      <AppTopographyBackground class="absolute top-0 -z-0 h-72 w-full" />
      <AppLogo v-if="operator" :name="operator.name" :src="operator.logo" class="absolute bottom-0 left-1/2 z-20 -translate-x-1/2" />
    </header>
    <main class="z-0 -mt-4 flex h-[calc(100%+2rem)] flex-1 flex-col justify-between">
      <RouterView />
    </main>
  </div>
  <EvNotifications />
</template>
