import { useNotifications } from '@kreait/web-components'
import { useI18n } from 'vue-i18n'

export function usePaymentNotifications() {
  const { t } = useI18n()
  const { addNotification } = useNotifications()

  const triggerPaymentFailedNotification = () => {
    addNotification({
      appearance: 'ERROR',
      description: t('payment.error.description'),
      title: t('payment.error.title'),
    })
  }

  const triggerPaymentSuccessNotification = () => {
    addNotification({
      appearance: 'SUCCESS',
      description: t('payment.success.description'),
      title: t('payment.success.title'),
    })
  }

  return { triggerPaymentFailedNotification, triggerPaymentSuccessNotification }
}
