import { ref } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import type { TariffOffer } from '~/services/price-management-service.types'
import type { PaymentIntentResponse } from '~/services/direct-payment-authorisation-service.types'

interface LSValue {
  /**
   * Represents the amount of money to deposit.
   *
   * Used to being displayed to the user and to configure Stripe Express Checkout.
   *
   * @see currency
   */
  authorizationAmount?: number

  /**
   * Represents the `authorizationAmount`'s currency.
   *
   * Used to being displayed to the user and to configure Stripe Express Checkout.
   *
   * @see authorizationAmount
   */
  currency?: string

  /**
   * Represents the autorization token for use against the elvah backend.
   *
   * Used as `Authorization Header` `Bearer` value.
   *
   * @see axios.setAuthToken
   */
  token?: string

  /**
   * Represents a `signedOffer` from a Tariff Offer.
   *
   * Used to get a payment intent from the elvah backend and
   * to authorize a `POST /v1/command/start` request.
   */
  offer?: string

  /**
   * Represents a Payment Intent's identifier.
   *
   * Used to receive an authorization `token` from the elvah backend.
   *
   * @see token
   */
  paymentIntentId?: string
}

interface LSKey {
  [key: string]: LSValue
}

const localStorageRef = useLocalStorage<string>('sessions', '{}')

const evseId = ref<string | null>(null)
const tariffOffer = ref<TariffOffer | null>(null)
const paymentIntent = ref<PaymentIntentResponse | null>(null)

export function useChargeStorage() {
  const getStorage = () => JSON.parse(localStorageRef.value) as LSKey

  const setStorage = (value: LSKey) => {
    localStorageRef.value = JSON.stringify(value)
  }

  const getSession = (evseId: string | null) => {
    if (evseId === null)
      return null

    const storage = getStorage()
    return storage[evseId] || null
  }

  const deleteSession = (evseId: string | null) => {
    if (evseId === null)
      return

    const storage = getStorage()
    delete storage[evseId]
    setStorage(storage)
  }

  const setSession = (evseId: string | null, value: LSValue) => {
    if (evseId === null)
      return

    const storage = getStorage()
    setStorage({ ...storage, [evseId]: value })
  }

  const isAuthenticated = (evseId: string | null) => {
    if (evseId === null)
      return false

    const storage = getStorage()
    const token = storage[evseId]?.token
    return !!token
  }

  const reset = (all = false) => {
    tariffOffer.value = null
    paymentIntent.value = null

    if (all) {
      deleteSession(evseId.value)
      evseId.value = null
    }
  }

  return { evseId, getStorage, getSession, isAuthenticated, paymentIntent, reset, setSession, tariffOffer }
}
