<script setup lang="ts">
import {
  DialogClose,
  DialogContent,
  // DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from 'radix-vue'
import { ref } from 'vue'
import IconClose from 'virtual:icons/elvah/close'
import IconChargePointLocked from 'virtual:icons/elvah/charge-point-locked'
import IconQuestion from 'virtual:icons/elvah/question'
import { Motion, Presence } from '@motionone/vue'
import { glide } from 'motion'
import { useRoute } from 'vue-router'
import { ROUTE_PATH_CHARGE } from '~/config/router/route-paths'
import { useChargeSession } from '~/composables/useChargeSession'

const route = useRoute()
const { chargeSession } = useChargeSession()

const open = ref(false)
</script>

<template>
  <DialogRoot v-model:open="open">
    <DialogTrigger class="flex appearance-none items-center text-sm font-semibold text-gray-800 hover:text-gray-900 focus:text-gray-900 focus:outline-none">
      <span class="underline">{{ $t('help.chargePointLocked.question') }}</span><IconQuestion class="w-6 shrink-0" />
    </DialogTrigger>
    <DialogPortal>
      <Presence>
        <DialogOverlay as-child class="eon-ahc-dialog-backdrop">
          <Motion
            :initial="{ opacity: 0 }"
            :animate="{ opacity: 1 }"
            :exit="{ opacity: 0 }"
            :transition="{
              // @ts-expect-error
              easing: glide({
                velocity: 5,
              }),
            }"
          />
        </DialogOverlay>
      </Presence>
      <Presence>
        <DialogContent as-child class="fixed bottom-0 left-1/2 z-50 flex w-screen max-w-xl -translate-x-1/2 flex-col overflow-hidden rounded-t-lg bg-white pb-10" :aria-describedby="undefined">
          <Motion
            :initial="{ bottom: '-50%', opacity: 0 }"
            :animate="{ bottom: '0%', opacity: 1 }"
            :exit="{ bottom: '-100%' }"
            :transition="{
              duration: 0.35,
              easing: 'ease-in-out',
            }"
          >
            <DialogTitle class="mb-6 border-b border-teal-100 px-4 py-3 text-center text-lg font-bold">
              {{ $t('help.chargePointLocked.sheet.title') }}
            </DialogTitle>
            <div class="flex flex-col px-6">
              <div class="mb-6 flex flex-col items-center rounded-lg bg-teal-50 p-6">
                <IconChargePointLocked class="mb-2 size-[53px] shrink-0" aria-hidden="true" />
                <h3 class="mb-6 font-bold">
                  {{ $t('help.chargePointLocked.sheet.subtitle') }}
                </h3>
                <ol class="mb-6 w-full">
                  <li class="mb-2.5 flex items-center text-sm font-medium before:mr-2 before:flex before:size-6 before:shrink-0 before:flex-col before:items-center before:justify-center before:rounded-full before:bg-gray-700 before:text-white before:content-['1'] before:salt">
                    {{ $t('help.chargePointLocked.sheet.step1') }}
                  </li>
                  <li class="flex items-center text-sm font-medium before:mr-2 before:flex before:size-6 before:shrink-0 before:flex-col before:items-center before:justify-center before:rounded-full before:bg-gray-700 before:text-white before:content-['2'] before:salt">
                    {{ $t('help.chargePointLocked.sheet.step2') }}
                  </li>
                </ol>
                <AppChargePointId v-if="chargeSession" :id="chargeSession?.evseId" />
              </div>
              <AppButton class="w-full" :to="{ path: ROUTE_PATH_CHARGE, query: route.query, replace: true }">
                {{ $t('cta.charging.start') }}
              </AppButton>
            </div>
            <DialogClose class="absolute right-2 top-2 appearance-none p-1 text-neutral-900 hover:text-gray-900 focus:text-gray-900 focus:outline-none" :aria-label="$t('close')">
              <IconClose class="size-6" aria-hidden="true" />
            </DialogClose>
          </Motion>
        </DialogContent>
      </Presence>
    </DialogPortal>
  </DialogRoot>
</template>
