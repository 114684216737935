<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useNotifications } from '@kreait/web-components'
import { useChargeStorage } from '~/composables/useChargeStorage'
import { ROUTE_PATH_CONNECT, ROUTE_PATH_PAYMENT } from '~/config/router/route-paths'
import { stripe } from '~/config/stripe'
import { stripeElements } from '~/config/stripe/elements'
import { destroy, stripePaymentElement } from '~/config/stripe/paymentElement'
import { usePaymentNotifications } from '~/composables/usePaymentNotifications'

const STRIPE_PAYMENT_ELEMENT_ID = 'stripe-payment-element'

const route = useRoute()
const router = useRouter()
const { dismissCurrentNotification } = useNotifications()
const { triggerPaymentFailedNotification, triggerPaymentSuccessNotification } = usePaymentNotifications()
const { tariffOffer, paymentIntent } = useChargeStorage()
const hide = ref(route.query.hide === 'true')
const loading = ref(false)

onMounted(async () => {
  const paymentElement = await stripePaymentElement()

  paymentElement?.mount(`#${STRIPE_PAYMENT_ELEMENT_ID}`)
  paymentElement?.collapse()

  setTimeout(() => {
    paymentElement?.focus()
  }, 1000)
})

onBeforeUnmount(async () => {
  destroy()
})

async function onSubmitCreditCardPayment() {
  const instance = await stripe()
  const elements = await stripeElements()

  loading.value = true

  if (paymentIntent.value !== null && !!instance && !!elements) {
    const { error } = await elements.submit()

    if (error) {
      if (import.meta.env.DEV)
        console.error('Stripe submit failed', error)

      loading.value = false
      dismissCurrentNotification()
      triggerPaymentFailedNotification()
      return
    }

    const result = await instance.confirmPayment({
      clientSecret: paymentIntent.value.clientSecret,
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    })

    if (result.error) {
      if (import.meta.env.DEV)
        console.warn('Stripe payment failed with error', result.error)

      loading.value = false
      dismissCurrentNotification()
      triggerPaymentFailedNotification()
    }
    else if (result.paymentIntent.status === 'requires_capture') {
      loading.value = false
      dismissCurrentNotification()
      triggerPaymentSuccessNotification()
      // remove all Stripe query params
      router.push({ path: ROUTE_PATH_CONNECT, query: route.query, replace: true })
    }
    else {
      if (import.meta.env.DEV)
        console.warn('Stripe payment status', result.paymentIntent.status)
    }
  }
  else {
    if (import.meta.env.DEV)
      console.warn('Stripe payment failed')
  }

  loading.value = false
}
</script>

<template>
  <form class="mt-8 flex flex-1 flex-col justify-between bg-white" novalidate @submit.prevent="onSubmitCreditCardPayment">
    <div class="flex h-full flex-col">
      <PaymentPricingList />
      <div :id="STRIPE_PAYMENT_ELEMENT_ID" class="mx-6 mb-2 mt-8" />
    </div>
    <AppFooter>
      <RadixDepositDialog v-if="!!tariffOffer" :deposit="tariffOffer.authorizationAmount.amount" :currency="tariffOffer.currency" />
      <AppButton class="mb-2" type="submit">
        {{ $t('payment.deposit.creditcard.pay') }}
      </AppButton>
      <AppButton v-if="!hide" appearance="secondary" :to="{ path: ROUTE_PATH_PAYMENT, query: route.query, replace: true }">
        {{ $t('payment.deposit.creditcard.payDifferently') }}
      </AppButton>
    </AppFooter>
  </form>
  <RadixLoadingDialog :show="loading" />
</template>
