import type { AxiosError } from 'axios'
import axios from 'axios'
import { ref } from 'vue'
import { useChargeStorage } from './useChargeStorage'
import { getCurrentChargeSession, sendStartCommand, sendStopCommand } from '~/services/direct-charge-service'
import type { ChargeSession } from '~/services/direct-charge-service.types'

const chargeSession = ref<ChargeSession>()
const conflict = ref(false)
const unauthorized = ref(false)

export function useChargeSession() {
  const fetchCurrent = async () => {
    let success = true

    await getCurrentChargeSession().then((response) => {
      chargeSession.value = response.data

      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.log('status:', chargeSession.value.status)
      }
    }).catch((error) => {
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError<unknown>
        unauthorized.value = e.response?.status === 401
      }

      success = false
    })

    return success
  }

  const start = async () => {
    const { evseId, getSession, tariffOffer } = useChargeStorage()
    const session = getSession(evseId.value)
    const signedTariffOffer = session?.offer ?? tariffOffer.value?.signedOffer

    if (evseId.value === null || !signedTariffOffer) {
      if (import.meta.env.DEV)
        console.warn('failed to send start command, missing params')

      return false
    }

    const evse = evseId.value
    let success = true

    await sendStartCommand({ evseId: evse, signedTariffOffer }).then((response) => {
      chargeSession.value = response.data.session
    }).catch((error) => {
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError<unknown>
        conflict.value = e.response?.status === 409
        unauthorized.value = (e.response?.status === 400 && !!((e.response?.data as any).errors?.at(0)?.detail === 'Invalid Tariff Signature')) || e.response?.status === 401
      }

      if (import.meta.env.DEV)
        console.warn('failed to send start command', error)

      success = false
    })

    return success
  }

  const stop = async () => {
    if (!chargeSession.value) {
      if (import.meta.env.DEV)
        console.warn('no charge session offer available')

      return false
    }

    let success = true

    await sendStopCommand({ chargeSessionId: chargeSession.value.id }).then((response) => {
      chargeSession.value = response.data.session
    }).catch((error) => {
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError<unknown>
        unauthorized.value = e.response?.status === 401
      }

      if (import.meta.env.DEV)
        console.warn('failed to send stop command', error)

      success = false
    })

    return success
  }

  return { chargeSession, conflict, fetchCurrent, start, stop, unauthorized }
}
