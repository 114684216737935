<template>
  <ChargingContent>
    <template #title>
      {{ $t('error.generic.title') }}
    </template>
    <template #description>
      {{ $t('error.generic.message') }}
    </template>
    <RadixSupportDialog />
  </ChargingContent>
</template>
