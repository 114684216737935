import { unauthorized } from '../config/axios'
import type { AuthenticateRequest, AuthenticateResponse, PaymentIntentRequest, PaymentIntentResponse, Response } from './direct-payment-authorisation-service.types'
import { AUTHENTICATE, PAYMENT_INTENTS } from './direct-payment-authorisation-service.routes'

const baseURL = import.meta.env.VITE_URL_SERVICE_DIRECT_PAYMENT_AUTHORISATION

export function postAuthenticate(params: AuthenticateRequest) {
  return unauthorized.post<Response<AuthenticateResponse>>(
    AUTHENTICATE,
    params,
    {
      baseURL,
    },
  )
}

export function postPaymentIntents(params: PaymentIntentRequest) {
  return unauthorized.post<Response<PaymentIntentResponse>>(
    PAYMENT_INTENTS,
    params,
    {
      baseURL,
    },
  )
}
