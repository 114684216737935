<script setup lang="ts">
import { TransitionPresets, useTransition } from '@vueuse/core'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useChargeSession } from '~/composables/useChargeSession'

const { locale } = useI18n()
const { chargeSession } = useChargeSession()
const now = ref(new Date().getTime())
let interval = 0

onMounted(() => {
  interval = window.setInterval(() => {
    now.value = new Date().getTime()
  }, 1000)
})

onBeforeUnmount(() => {
  window.clearInterval(interval)
})

const kWh = computed(() => !!chargeSession.value && !!chargeSession.value.consumptionInkWh
  ? chargeSession.value.consumptionInkWh
  : 0,
)

const duration = computed(() => {
  let min = 0
  let sec = 0

  if (!!chargeSession.value && !!chargeSession.value.initDate) {
    const started = new Date(chargeSession.value.initDate).getTime()
    const delta = Math.floor((now.value - started) / 1000)

    min = Math.floor(delta / 60)
    sec = delta - min * 60
  }

  return { min, sec }
})

const fadedKWh = useTransition(() => kWh.value, {
  duration: 2000,
  transition: TransitionPresets.easeInOutCubic,
})
</script>

<template>
  <div class="flex flex-col items-center">
    <h1 class="mb-2 px-4 text-center text-lg font-bold">
      {{ $t('chargingState.chargingIdle.title') }}
    </h1>
    <AppBatteryIndicator
      v-if="typeof chargeSession?.currentStateOfChargeInPercent === 'number'"
      class="mb-3"
      :percent="chargeSession?.currentStateOfChargeInPercent"
    />
    <Transition mode="out-in" name="fade">
      <div v-if="0 < kWh" class="mb-10 flex flex-col items-center">
        <span class="block text-[56px] font-bold leading-[56px] text-gray-900">{{
          fadedKWh.toLocaleString(locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        }}</span>
        <span class="text-sm text-gray-600">{{ $t('chargingState.chargingIdle.kWh') }}</span>
      </div>
    </Transition>
    <div class="flex flex-col items-center text-gray-600">
      <span class="block text-lg font-bold">{{ $t('chargingState.chargingIdle.duration.value', duration) }}</span>
      <span class="text-sm text-gray-600">{{ $t('chargingState.chargingIdle.duration.label') }}</span>
    </div>
  </div>
</template>
