import { computed, ref, watch } from 'vue'
import { useChargeStorage } from './useChargeStorage'

type Operator = {
  'operator-prefix'?: string[]
  'logo': string
  'name': string
}

function getEnvPrefixes(key: string): string[] {
  const envValue = import.meta.env[key]
  return envValue ? envValue.split(',') : []
}

const operators: Record<string, Operator> = {
  'mer': {
    'operator-prefix': getEnvPrefixes('VITE_OPERATOR_MER_PREFIX'),
    'logo': '/logos/mer.png',
    'name': 'Mer',
  },
  'eliso': {
    'operator-prefix': getEnvPrefixes('VITE_OPERATOR_ELISO_PREFIX'),
    'logo': '/logos/eliso.png',
    'name': 'eliso',
  },
  'e.on': {
    'operator-prefix': getEnvPrefixes('VITE_OPERATOR_EON_PREFIX'),
    'logo': '/logos/eon.png',
    'name': 'e.on Drive',
  },
  'elvah-sim': {
    'operator-prefix': getEnvPrefixes('VITE_OPERATOR_ELVAH_SIM_PREFIX'),
    'logo': '/logos/elvah.png',
    'name': 'elvah Simulator',
  },
  'default': {
    logo: '/logos/elvah.png',
    name: '-',
  },
}

const { evseId } = useChargeStorage()
const _operator = ref<Operator>()
const operator = computed(() => _operator.value)

watch(() => evseId.value, () => {
  if (typeof evseId.value !== 'undefined') {
    if (evseId.value !== null) {
      for (const key in operators) {
        if (operators[key]['operator-prefix']) {
          for (const prefix of operators[key]['operator-prefix']!) {
            if (evseId.value.startsWith(prefix)) {
              _operator.value = operators[key] || operators.default
              return
            }
          }
        }
      }
    }
    else if (!_operator.value) {
      // once charging stopped, evseId is set to null
      // so, we don't overwrite the operator here
      // otherwise ChargingStopped.vue would display
      // an empty string instead of the operator's name.
      _operator.value = operators.default
    }
  }
})

export function useOperator() {
  return { operator }
}
