export enum ChargingState {
  WAIT_FOR_SESSION_DATA,
  START_REQUESTED,
  STATION_AWAKE,
  INITIAL_CONNECTION,
  INITIAL_CONNECTION_DELAYED,
  CHARGING,
  STOP_REQUESTED,
  STOPPED,
  ERROR_START_COMMUNICATION_TIMEOUT,
  ERROR_START_CPO_CABLE_CONNECTION,
  ERROR_START_CPO_REJECTED_REQUEST,
  ERROR_START_EVESE_IN_USE,
  ERROR_START_EVSE_OUT_OF_SERVICE,
  ERROR_START_EVSE_REJECTED_REQUEST,
  ERROR_START_EVSE_RESERVED,
  ERROR_START_INTERNAL,
  ERROR_START_PAYMENT_EXPIRED,
  ERROR_STOP_FAILED,
  UI_ERROR_GENERIC,
  UI_ERROR_START_CONFLICT,
}
