<script setup lang="ts">
interface Props {
  percent: number
}

const props = withDefaults(defineProps<Props>(), {
  percent: 0,
})
</script>

<template>
  <div class="relative h-2 rounded-full bg-gray-200">
    <div class="absolute left-0 top-0 h-full rounded-full bg-gray-700 transition-all duration-300" :style="{ width: `${Math.min(Math.max(0, props.percent), 100)}%` }" />
  </div>
</template>
