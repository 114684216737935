import type Stripe from '@stripe/stripe-js'
import type { StripeElementLocale, StripeElementsOptionsMode } from '@stripe/stripe-js'
import { useI18n } from 'vue-i18n'
import { stripe } from './index'
import { useChargeStorage } from '~/composables/useChargeStorage'

let elements: Stripe.StripeElements | undefined
let expressElement: Stripe.StripeExpressCheckoutElement | undefined

export async function stripeExpressCheckoutElement() {
  const { locale } = useI18n()

  if (typeof expressElement === 'undefined') {
    const { evseId, getSession, tariffOffer } = useChargeStorage()
    const stripeInstance = await stripe()
    const session = getSession(evseId.value)

    if (stripeInstance) {
      const options = {
        locale: locale.value as StripeElementLocale,
        mode: 'payment',
        amount: session?.authorizationAmount ?? tariffOffer.value?.authorizationAmount.amount,
        currency: session?.currency?.toLowerCase() ?? tariffOffer.value?.currency.toLocaleLowerCase(),
        captureMethod: 'manual',
      } satisfies StripeElementsOptionsMode
      elements = stripeInstance.elements(options)

      expressElement = elements?.create('expressCheckout', {
        buttonHeight: 48,
        layout: {
          maxColumns: 1,
          overflow: 'never',
        },
      })
    }

    if (!expressElement) {
      if (import.meta.env.DEV)
        console.error('failed to create Stripe ExpressCheckoutElement instance')
    }
  }

  return expressElement
}

export async function confirmPayment() {
  const { paymentIntent } = useChargeStorage()
  const stripeInstance = await stripe()

  if (paymentIntent.value !== null && !!stripeInstance && !!elements) {
    const { error: submitError } = await elements.submit()

    if (submitError)
      return false

    const { error: confirmPaymentError } = await stripeInstance?.confirmPayment({
      elements,
      clientSecret: paymentIntent.value.clientSecret,
      confirmParams: {
        return_url: window.location.href,
      },
    })

    if (confirmPaymentError)
      return false

    return true
  }

  return false
}

export function destroy() {
  expressElement?.destroy()
  expressElement = undefined
  elements = undefined
}
