<script setup lang="ts">
import IconBattery1 from 'virtual:icons/elvah/battery-1.svg'
import IconBattery2 from 'virtual:icons/elvah/battery-2.svg'
import IconBattery3 from 'virtual:icons/elvah/battery-3.svg'
import IconBattery4 from 'virtual:icons/elvah/battery-4.svg'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

interface Props {
  percent: number
}

const props = withDefaults(defineProps<Props>(), {
  percent: 0,
})

const { locale } = useI18n()

const icon = computed(() => {
  if (props.percent < 25)
    return IconBattery1
  else if (props.percent >= 25 && props.percent < 50)
    return IconBattery2
  else if (props.percent >= 50 && props.percent < 75)
    return IconBattery3
  else
    return IconBattery4
})

const percent = computed(() => (props.percent).toLocaleString(locale.value, {
  style: 'percent',
}))
</script>

<template>
  <div class="flex items-center text-gray-600">
    <component :is="icon" class="mr-2 size-6 shrink-0" />
    <span class="text-xs font-medium salt">{{ percent }}</span>
  </div>
</template>
