import { sanitizeUrl } from '@braintree/sanitize-url'

export function getURLSearchParams() {
  const href = sanitizeUrl(window.location.href)

  try {
    const url = new URL(href)
    return url.searchParams
  }
  catch (error) {
    return undefined
  }
}
