import type { CreateAxiosDefaults } from 'axios'
import axios from 'axios'

let authToken: string | undefined

export function setAuthToken(value: string) {
  authToken = value
}

const defaultConfig: CreateAxiosDefaults = {
  headers: {
    Accept: 'application/json',
  },
  paramsSerializer: {
    indexes: null,
  },
}

/**
 * `unauthorized` does not use authentication
 */
export const unauthorized = axios.create(defaultConfig)

/**
 * `authorized` uses `authToken` token
 *
 * @see setAuthToken
 */
export const authorized = axios.create(defaultConfig)

authorized.interceptors.request.use(
  async (config) => {
    if (authToken) {
      // make sure config.headers is not undefined
      config.headers = config.headers ?? {}
      config.headers.Authorization = `Bearer ${authToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default authorized
