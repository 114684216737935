<script setup lang="ts">
import IconCheckmark from 'virtual:icons/elvah/checkmark'
import IconSuccess from 'virtual:icons/elvah/success'
import IconWarning from 'virtual:icons/elvah/warning'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ChargingState } from '~/types/ChargingState.enum'
import { useChargeState } from '~/composables/useChargeState'
import { useFakeProgress } from '~/composables/useFakeProgress'
import { ROUTE_PATH_INDEX, ROUTE_PATH_SCAN } from '~/config/router/route-paths'

const route = useRoute()
const router = useRouter()

const { fetchStatus, onStart, onStop, reset, startPoll, state } = useChargeState()
const { percent, reset: resetFakeProgress, start: startFakeProgress } = useFakeProgress()

async function onRetry() {
  resetFakeProgress()
  await onStop()

  if (await onStart())
    await startPoll()
}

async function onTryAgainPayment() {
  router.push({ path: ROUTE_PATH_INDEX, query: route.query, replace: true })
}

function onAbort() {
  reset(true)
}

onMounted(async () => {
  // Fetch the status once initially.
  // If the session is already charging,
  // we don't need to display the
  // ChargingState.STATION_AWAKE,
  // ChargingState.INITIAL_CONNECTION or
  // ChargingState.INITIAL_CONNECTION_DELAYED
  // states at all but can automatically go to the
  // ChargingState.CHARGING state and start polling
  if (!await fetchStatus())
    return

  if (state.value === ChargingState.CHARGING) {
    await startPoll()
  }
  else {
    startFakeProgress()
    if (await onStart())
      await startPoll()
  }
})
</script>

<template>
  <div class="flex h-full flex-col">
    <div class="relative mt-20 flex flex-col items-center">
      <Transition mode="out-in" name="fade">
        <AppLoadingSpinner
          v-if="
            state === ChargingState.WAIT_FOR_SESSION_DATA
              || state === ChargingState.START_REQUESTED
              || state === ChargingState.STATION_AWAKE
              || state === ChargingState.INITIAL_CONNECTION
              || state === ChargingState.INITIAL_CONNECTION_DELAYED
              || state === ChargingState.STOP_REQUESTED
          "
          class="mb-3"
        />
        <IconSuccess
          v-else-if="state === ChargingState.STOPPED"
          class="mb-3 size-12"
          aria-hidden="true"
        />
        <IconWarning
          v-else-if="
            state === ChargingState.ERROR_START_COMMUNICATION_TIMEOUT
              || state === ChargingState.ERROR_START_EVSE_REJECTED_REQUEST
              || state === ChargingState.ERROR_START_INTERNAL
              || state === ChargingState.ERROR_START_EVSE_OUT_OF_SERVICE
              || state === ChargingState.ERROR_START_CPO_REJECTED_REQUEST
              || state === ChargingState.ERROR_START_EVSE_RESERVED
              || state === ChargingState.ERROR_START_EVESE_IN_USE
              || state === ChargingState.ERROR_START_PAYMENT_EXPIRED
              || state === ChargingState.ERROR_STOP_FAILED
          "
          class="mb-3 size-12"
          aria-hidden="true"
        />
      </Transition>
      <Transition mode="out-in" name="fade">
        <IconCheckmark
          v-if="
            state === ChargingState.STATION_AWAKE
              || state === ChargingState.INITIAL_CONNECTION
              || state === ChargingState.INITIAL_CONNECTION_DELAYED
          " class="absolute left-1/2 top-1 size-12 -translate-x-1/2"
          aria-hidden="true"
        />
      </Transition>
      <Transition mode="out-in" name="fade">
        <ChargingAwaitSessionData v-if="state === ChargingState.WAIT_FOR_SESSION_DATA" />
        <ChargingStartRequested v-else-if="state === ChargingState.START_REQUESTED" />
        <ChargingStationAwake v-else-if="state === ChargingState.STATION_AWAKE" />
        <ChargingInitialConnection v-else-if="state === ChargingState.INITIAL_CONNECTION" />
        <ChargingDelayInfo v-else-if="state === ChargingState.INITIAL_CONNECTION_DELAYED" />
        <ChargingIdle v-else-if="state === ChargingState.CHARGING" />
        <ChargingStopRequested v-else-if="state === ChargingState.STOP_REQUESTED" />
        <ChargingStopped v-else-if="state === ChargingState.STOPPED" />
        <ChargingErrorCommunicationTimeout v-else-if="state === ChargingState.ERROR_START_COMMUNICATION_TIMEOUT" />
        <ChargingErrorEvseRejectedRequest v-else-if="state === ChargingState.ERROR_START_EVSE_REJECTED_REQUEST" />
        <ChargingErrorInternal v-else-if="state === ChargingState.ERROR_START_INTERNAL" />
        <ChargingErrorEvseOutOfService v-else-if="state === ChargingState.ERROR_START_EVSE_OUT_OF_SERVICE" />
        <ChargingErrorCpoCableConnection v-else-if="state === ChargingState.ERROR_START_CPO_CABLE_CONNECTION" />
        <ChargingErrorCpoRejectedRequest v-else-if="state === ChargingState.ERROR_START_CPO_REJECTED_REQUEST" />
        <ChargingErrorEvseReserved v-else-if="state === ChargingState.ERROR_START_EVSE_RESERVED" />
        <ChargingErrorEvseInUse v-else-if="state === ChargingState.ERROR_START_EVESE_IN_USE" />
        <ChargingErrorPaymentExpired v-else-if="state === ChargingState.ERROR_START_PAYMENT_EXPIRED" />
        <ChargingErrorStopFailed v-else-if="state === ChargingState.ERROR_STOP_FAILED" />
      </Transition>
    </div>
  </div>
  <AppFooter>
    <Transition mode="out-in" name="fade">
      <AppProgressBar
        v-if="
          state === ChargingState.START_REQUESTED
            || state === ChargingState.STATION_AWAKE
            || state === ChargingState.INITIAL_CONNECTION
            || state === ChargingState.INITIAL_CONNECTION_DELAYED
        "
        class="mb-6 w-full"
        :percent="percent"
      />
    </Transition>
    <Transition mode="out-in" name="fade">
      <AppButton
        v-if="state === ChargingState.CHARGING"
        class="mb-3"
        @click="onStop"
      >
        {{ $t('cta.charging.end') }}
      </AppButton>
      <AppButton
        v-else-if="state === ChargingState.ERROR_START_PAYMENT_EXPIRED"
        class="mb-3"
        @click="onTryAgainPayment"
      >
        {{ $t('cta.charging.continue') }}
      </AppButton>
      <AppButton
        v-else-if="
          state === ChargingState.ERROR_START_COMMUNICATION_TIMEOUT
            || state === ChargingState.ERROR_START_EVSE_REJECTED_REQUEST
            || state === ChargingState.ERROR_START_INTERNAL
            || state === ChargingState.ERROR_START_EVSE_OUT_OF_SERVICE
            || state === ChargingState.ERROR_START_CPO_REJECTED_REQUEST
            || state === ChargingState.ERROR_START_EVSE_RESERVED
            || state === ChargingState.ERROR_START_EVESE_IN_USE
            || state === ChargingState.ERROR_STOP_FAILED
        "
        class="mb-3"
        @click="onRetry"
      >
        {{ $t('cta.charging.retry') }}
      </AppButton>
    </Transition>
    <Transition mode="out-in" name="fade">
      <RouterLink
        v-if="
          state === ChargingState.ERROR_START_COMMUNICATION_TIMEOUT
            || state === ChargingState.ERROR_START_EVSE_REJECTED_REQUEST
            || state === ChargingState.ERROR_START_INTERNAL
            || state === ChargingState.ERROR_START_EVSE_OUT_OF_SERVICE
            || state === ChargingState.ERROR_START_CPO_REJECTED_REQUEST
            || state === ChargingState.ERROR_START_EVSE_RESERVED
            || state === ChargingState.ERROR_START_EVESE_IN_USE
        "
        class="appearance-none px-5 py-3 text-center text-sm font-semibold hover:text-gray-900 focus:text-gray-900 focus:outline-none"
        :to="{ path: ROUTE_PATH_SCAN, replace: true }"
        @click="onAbort"
      >
        {{ $t('cta.charging.abort') }}
      </RouterLink>
      <RouterLink
        v-else-if="state === ChargingState.ERROR_STOP_FAILED"
        class="appearance-none px-5 py-3 text-center text-sm font-semibold hover:text-gray-900 focus:text-gray-900 focus:outline-none"
        :to="{ path: ROUTE_PATH_SCAN, replace: true }"
        @click="onAbort"
      >
        {{ $t('cta.charging.startOver') }}
      </RouterLink>
    </Transition>
    <RadixSupportDialog
      v-if="
        state === ChargingState.WAIT_FOR_SESSION_DATA
          || state === ChargingState.START_REQUESTED
          || state === ChargingState.STATION_AWAKE
          || state === ChargingState.INITIAL_CONNECTION
          || state === ChargingState.INITIAL_CONNECTION_DELAYED
          || state === ChargingState.CHARGING
          || state === ChargingState.STOP_REQUESTED
          || state === ChargingState.STOPPED
      "
    />
  </AppFooter>
</template>
