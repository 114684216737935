<script setup lang="ts">
import {
  // DialogClose,
  DialogContent,
  // DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  // DialogTrigger,
} from 'radix-vue'
import { Motion, Presence } from '@motionone/vue'
import { glide } from 'motion'
import { computed } from 'vue'

interface Props {
  show: boolean
}

const props = withDefaults(defineProps<Props>(), {
  show: false,
})

const open = computed(() => props.show)
</script>

<template>
  <DialogRoot v-model:open="open">
    <DialogPortal>
      <Presence>
        <DialogOverlay as-child class="eon-ahc-dialog-backdrop-light">
          <Motion
            :initial="{ opacity: 0 }"
            :animate="{ opacity: 1 }"
            :exit="{ opacity: 0 }"
            :transition="{
              // @ts-expect-error
              easing: glide({
                velocity: 5,
              }),
            }"
          />
        </DialogOverlay>
      </Presence>
      <Presence>
        <DialogContent as-child class="fixed left-0 top-0 z-50 flex h-screen w-screen flex-col overflow-hidden" :aria-describedby="undefined">
          <div class="flex h-full flex-col">
            <DialogTitle class="absolute -top-10 left-full">
              {{ $t('payment.deposit.loading.title') }}
            </DialogTitle>
            <div class="flex h-full flex-col items-center justify-center" aria-hidden="true">
              <AppLoadingSpinner class="mb-3" />
              <ChargingContent>
                <template #title>
                  {{ $t('payment.deposit.loading.title') }}
                </template>
              </ChargingContent>
            </div>
          </div>
        </DialogContent>
      </Presence>
    </DialogPortal>
  </DialogRoot>
</template>
