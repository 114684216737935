<script setup lang="ts">
import type { RouteLocationNormalized } from 'vue-router'
import { useRoute, useRouter } from 'vue-router'
import { onBeforeMount, onMounted } from 'vue'
import { ROUTE_PATH_PAYMENT } from '~/config/router/route-paths'
import { offerGuard, paymentIntentGuard } from '~/config/router/route-guards'
import { useChargeStorage } from '~/composables/useChargeStorage'

const { reset } = useChargeStorage()

onBeforeMount(() => {
  reset()
})

onMounted(async () => {
  const route = useRoute()
  const router = useRouter()

  await offerGuard({} as RouteLocationNormalized, {} as RouteLocationNormalized, () => {})
  await paymentIntentGuard({} as RouteLocationNormalized, {} as RouteLocationNormalized, () => {})
  router.push({ path: ROUTE_PATH_PAYMENT, query: route.query })
})
</script>

<template>
  <div class="flex h-full flex-col">
    <div class="mt-20 flex h-full flex-col items-center">
      <AppLoadingSpinner class="mb-3" />
      <ChargingContent>
        <template #title>
          {{ $t('loading.title') }}
        </template>
        <template #description>
          {{ $t('loading.description.a') }}<br>{{ $t('loading.description.b') }}
        </template>
      </ChargingContent>
    </div>
  </div>
</template>
