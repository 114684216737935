import type { StripeElementLocale } from '@stripe/stripe-js'
import type Stripe from '@stripe/stripe-js'
import { useI18n } from 'vue-i18n'
import appearance from './appearance'
import { stripe } from './index'
import { useChargeStorage } from '~/composables/useChargeStorage'

let elements: Stripe.StripeElements | null | undefined = null

export async function stripeElements() {
  if (!elements) {
    const { locale } = useI18n()
    const instance = await stripe()
    const { paymentIntent } = useChargeStorage()

    if (paymentIntent.value !== null) {
      elements = instance?.elements({
        appearance,
        clientSecret: paymentIntent.value.clientSecret,
        locale: locale.value as StripeElementLocale,
      })
    }
    else {
      if (import.meta.env.DEV)
        console.error('failed to create Stripe Elements instance – no config')
    }

    if (!elements) {
      if (import.meta.env.DEV)
        console.error('failed to create Stripe Elements instance')
    }
  }

  return elements
}
