import { ref } from 'vue'

export function useFakeProgress() {
  const maxSeconds = 120
  const percent = ref(0)
  let seconds = 0
  let interval: number | undefined

  const stop = () => {
    if (typeof interval === 'number') {
      window.clearInterval(interval)
      interval = undefined
    }
  }

  const reset = () => {
    seconds = 0
  }

  const update = () => {
    ++seconds
    percent.value = 100 * seconds / maxSeconds

    if (seconds === maxSeconds)
      stop()
  }

  const start = () => {
    if (typeof interval === 'undefined')
      interval = window.setInterval(() => { update() }, 1000)
  }

  return { percent, reset, start }
}
