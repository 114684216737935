<script setup lang="ts">
import {
  DialogClose,
  DialogContent,
  // DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from 'radix-vue'
import { computed, ref } from 'vue'
import IconClose from 'virtual:icons/elvah/close'
import { Motion, Presence } from '@motionone/vue'
import { glide } from 'motion'
import { useI18n } from 'vue-i18n'

interface Props {
  currency: string
  deposit: number
}

const props = defineProps<Props>()

const { locale } = useI18n()

const value = computed(() => (props.deposit / 100).toLocaleString(locale.value, {
  style: 'currency',
  currency: props.currency,
}))

const open = ref(false)
</script>

<template>
  <DialogRoot v-model:open="open">
    <DialogTrigger class="mb-4 appearance-none text-xs text-gray-800 underline hover:text-gray-900 focus:text-gray-900 focus:outline-none">
      {{ $t('payment.deposit.cta', { value }) }}
    </DialogTrigger>
    <DialogPortal>
      <Presence>
        <DialogOverlay as-child class="eon-ahc-dialog-backdrop">
          <Motion
            :initial="{ opacity: 0 }"
            :animate="{ opacity: 1 }"
            :exit="{ opacity: 0 }"
            :transition="{
              // @ts-expect-error
              easing: glide({
                velocity: 5,
              }),
            }"
          />
        </DialogOverlay>
      </Presence>
      <Presence>
        <DialogContent as-child class="fixed bottom-0 left-1/2 z-50 flex w-screen max-w-xl -translate-x-1/2 flex-col overflow-hidden rounded-t-lg bg-white pb-10" :aria-describedby="undefined">
          <Motion
            :initial="{ bottom: '-50%', opacity: 0 }"
            :animate="{ bottom: '0%', opacity: 1 }"
            :exit="{ bottom: '-100%' }"
            :transition="{
              duration: 0.35,
              easing: 'ease-in-out',
            }"
          >
            <DialogTitle class="mb-6 border-b border-teal-100 px-4 py-3 text-center text-lg font-bold">
              {{ $t('payment.deposit.sheet.title', { value }) }}
            </DialogTitle>
            <div class="px-6">
              <p class="mb-6 text-sm text-neutral-900">
                {{ $t('payment.deposit.sheet.description', { value }) }}
              </p>
              <AppButton class="w-full" @click="open = false">
                {{ $t('payment.deposit.sheet.cta') }}
              </AppButton>
            </div>
            <DialogClose class="absolute right-2 top-2 appearance-none p-1 text-neutral-900 hover:text-gray-900 focus:text-gray-900 focus:outline-none" :aria-label="$t('close')">
              <IconClose class="size-6" aria-hidden="true" />
            </DialogClose>
          </Motion>
        </DialogContent>
      </Presence>
    </DialogPortal>
  </DialogRoot>
</template>
