<template>
  <ChargingContent>
    <template #title>
      {{ $t('chargingState.error.cpoCableConnection.title') }}
    </template>
    <template #description>
      {{ $t('chargingState.error.cpoCableConnection.description') }}
    </template>
    <RadixSupportDialog />
  </ChargingContent>
</template>
