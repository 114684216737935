<template>
  <div class="flex flex-col items-center">
    <h1 class="mb-2 px-4 text-center text-lg font-bold">
      <slot name="title" />
    </h1>
    <p class="px-4 text-center text-sm text-gray-700">
      <slot name="description" />
    </p>
    <div v-if="$slots.default" class="mt-3">
      <slot />
    </div>
  </div>
</template>
