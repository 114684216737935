<template>
  <ChargingContent>
    <template #title>
      {{ $t('chargingState.error.stopFailed.title') }}
    </template>
    <template #description>
      {{ $t('chargingState.error.stopFailed.description.a') }}<br><br>{{ $t('chargingState.error.stopFailed.description.b') }}
    </template>
    <RadixSupportDialog />
  </ChargingContent>
</template>
