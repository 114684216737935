<template>
  <ChargingContent>
    <template #title>
      {{ $t('chargingState.startRequested.title') }}
    </template>
    <template #description>
      {{ $t('chargingState.startRequested.description.a') }}<br>{{ $t('chargingState.startRequested.description.b') }}
    </template>
  </ChargingContent>
</template>
