// POST
export const COMMAND_START = '/v1/command/start'

// POST
export const COMMAND_STOP = '/v1/command/stop'

// POST
export const COMMAND_CANCEL_STOP = '/v1/command/cancel-stop'

// GET
export const SESSIONS = '/v1/sessions'

// GET
export const SESSION_BY_ID = '/v1/sessions/:id'

// GET
export const SESSION_CURRENT = '/v1/current-session'
