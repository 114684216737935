<script setup lang="ts">
import IconConnect from 'virtual:icons/elvah/connect'
import { useRoute } from 'vue-router'
import { onBeforeUnmount } from 'vue'
import { useNotifications } from '@kreait/web-components'
import { ROUTE_PATH_CHARGE, ROUTE_PATH_SCAN } from '~/config/router/route-paths'
import { useChargeStorage } from '~/composables/useChargeStorage'

const route = useRoute()
const { evseId, reset } = useChargeStorage()
const { dismissCurrentNotification } = useNotifications()

onBeforeUnmount(() => {
  dismissCurrentNotification()
})

async function onAbort() {
  reset(true)
}
</script>

<template>
  <div class="flex h-full flex-col">
    <div class="mt-20 flex flex-col items-center">
      <IconConnect class="size-[53px]" aria-hidden="true" />
      <ChargingContent>
        <template #title>
          {{ $t('connect.title') }}
        </template>
      </ChargingContent>
      <AppChargePointId :id="evseId" class="mb-1.5 mt-36" />
      <RadixChargePointLockedDialog />
    </div>
  </div>
  <AppFooter>
    <AppButton :to="{ path: ROUTE_PATH_CHARGE, query: route.query, replace: true }" class="mb-2">
      {{ $t('connect.cta.primary') }}
    </AppButton>
    <AppButton :to="{ path: ROUTE_PATH_SCAN, query: { lang: route.query.lang }, replace: true }" appearance="secondary" @click="onAbort">
      {{ $t('connect.cta.secondary') }}
    </AppButton>
  </AppFooter>
</template>
