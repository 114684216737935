<script setup lang="ts">
import type { RouterLinkProps } from 'vue-router'

interface Props {
  appearance?: 'primary' | 'secondary'
  href?: string
  to?: RouterLinkProps
  type?: 'button' | 'submit'
}

const props = withDefaults(defineProps<Props>(), {
  appearance: 'primary',
  type: 'button',
})
</script>

<template>
  <a v-if="props.href" :href="props.href" class="button" :class="[appearance]">
    <slot />
  </a>
  <RouterLink v-else-if="props.to" :to="props.to" class="button" :class="[appearance]">
    <slot />
  </RouterLink>
  <button v-else class="button" :class="[appearance]" :type="type">
    <slot />
  </button>
</template>

<style scoped lang="scss">
.button {
  @apply py-3.5 px-5 text-sm font-semibold appearance-none focus:outline-none text-center;

  &.primary {
    @apply bg-black text-white rounded-md hover:bg-gray-900 focus:bg-gray-900;
  }

  &.secondary {
    @apply rounded-md hover:underline focus:underline;
  }
}
</style>
