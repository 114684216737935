<script lang="ts" setup>
import { useOperator } from '~/composables/useOperator'

const { operator } = useOperator()
</script>

<template>
  <ChargingContent>
    <template #title>
      {{ $t('chargingState.stopped.title', { value: operator?.name }) }}
    </template>
  </ChargingContent>
</template>
