<template>
  <ChargingContent class="flex flex-col items-center">
    <template #title>
      {{ $t('chargingState.showInitialConnection.title') }}
    </template>
    <template #description>
      {{ $t('chargingState.showInitialConnection.description.a') }}<br>{{ $t('chargingState.showInitialConnection.description.b') }}
    </template>
  </ChargingContent>
</template>
