import type Stripe from '@stripe/stripe-js'
import { stripeElements } from './elements'

let paymentElement: Stripe.StripePaymentElement | undefined

export async function stripePaymentElement() {
  const elements = await stripeElements()

  if (typeof paymentElement === 'undefined') {
    paymentElement = elements?.create('payment', {
      wallets: {
        applePay: 'never',
        googlePay: 'never',
      },
      paymentMethodOrder: ['card'],
      layout: {
        defaultCollapsed: false,
        type: 'tabs',
      },
    })

    if (!paymentElement) {
      if (import.meta.env.DEV)
        console.error('failed to create Stripe PaymentElement instance')
    }
  }

  return paymentElement
}

export function destroy() {
  paymentElement?.destroy()
  paymentElement = undefined
}
